var transferred = 0;
var chatButtonId = 'liveagent_button_online_5738F000000GmdD'

function goToChatBot() 
{
  let chatButtons = document.getElementsByClassName('helpButtonEnabled');
  document.getElementById(chatButtonId).style.display = "none";

  if (chatButtons)
  {
    let originalChatButton = chatButtons[0];
    originalChatButton.click();
  }
}

function hideModalOnMinimize(isChatWindowHidden)
{
  if (isChatWindowHidden)
  {
    window.embedded_svc.showHelpButton();
  }
  else
  {
    window.embedded_svc.hideHelpButton();
  }
}

function loadChatBotScripts()
{
  if (document.getElementById("chatSalesForce") === null)
  {
    const eswJs = document.createElement('script');
    eswJs.setAttribute("src", "https://service.force.com/embeddedservice/5.0/esw.min.js");
    eswJs.setAttribute("type", "text/javascript");
    eswJs.setAttribute("id", "chatSalesForce");
    eswJs.addEventListener('load', overrideBotSalesforce, false);
    eswJs.addEventListener('error', tryFallback, false);
    document.body.appendChild(eswJs);
  }
}

function overrideBotSalesforce()
{
  window.embedded_svc.addEventHandler("afterMaximize", () => 
  {
    hideModalOnMinimize(false);
    document.getElementById(chatButtonId).style.display = "none";
  });

  window.embedded_svc.addEventHandler("onChatTransferSuccessful", function ()
  {
    transferred = 1;
  });

  window.embedded_svc.addEventHandler("onChatEndedByAgent", function ()
  {
    afterChatEnded()
  });

  window.embedded_svc.addEventHandler("onChatEndedByChasitor", function ()
  {
    afterChatEnded();
  });

  window.embedded_svc.addEventHandler("onChatEndedByChatbot", function ()
  {
    afterChatEnded();
  });

  window.embedded_svc.addEventHandler("onIdleTimeoutOccurred", function ()
  {
    afterChatEnded()
  });

  window.embedded_svc.addEventHandler("afterDestroy", () =>
  {
    document.getElementById(chatButtonId).style.display = "block";
  });
  window.embedded_svc.addMessageHandler("session.onLoad", function ()
  {
    window.embedded_svc.utils.addEventHandler('onSettingsCallCompleted', function ()
    {
      var contentBtnChat = document.getElementsByClassName('embeddedServiceHelpButton');
      if (contentBtnChat && contentBtnChat[0])
      {
        var button = contentBtnChat[0].getElementsByClassName('helpButtonEnabled');
        if (button && button[0])
        {
          button[0].addEventListener("click", function ()
          {
            let readyEvents = window.embedded_svc.utils.eventHandlers['ready'];
            if (readyEvents && readyEvents.indexOf(addPrivacyNotice) >= 0)
            {
              return;
            }
            window.embedded_svc.utils.addEventHandler('ready', addPrivacyNotice);
          });
        }
      }
    });
  });
  initBotESW();
}

function tryFallback()
{
  var s = document.createElement('script');
  s.setAttribute('src', 'https://nic20.my.salesforce.com/embeddedservice/5.0/esw.min.js'); //CHAT DE PRODUCCION
  s.onload = function ()
  {
    initESW(null);
  };
  document.body.appendChild(s);
}

const initBotESW = function ()
{
  var chatLanguageCode = document.getElementById('chatLanguageCode').innerHTML;
  var chatDefaultMinimizedText = document.getElementById('chatDefaultMinimizedText').innerHTML;
  var chatDisabledMinimizedText = document.getElementById('chatDisabledMinimizedText').innerHTML;
  var chatLoadingText = document.getElementById('chatLoadingText').innerHTML;
  var urlSalesForce = document.getElementById('urlSalesForce').innerHTML;
  var urlChatBot = document.getElementById('urlChatBot').innerHTML;
  var urlAvatarChatBot = document.getElementById('urlAvatarChatBot').innerHTML;
  var salesForceId = document.getElementById('salesForceId').innerHTML;
  var baseLiveAgentContentURL = document.getElementById('baseLiveAgentContentURL').innerHTML;
  var deploymentId = document.getElementById('deploymentId').innerHTML;
  var buttonId = document.getElementById('buttonId').innerHTML;
  var baseLiveAgentURL = document.getElementById('baseLiveAgentURL').innerHTML;
  var eswLiveAgentDevName = document.getElementById('eswLiveAgentDevName').innerHTML;

  window.embedded_svc.settings.language = chatLanguageCode;
  window.embedded_svc.settings.chatbotAvatarImgURL = urlAvatarChatBot;
  window.embedded_svc.settings.defaultMinimizedText = chatDefaultMinimizedText;
  window.embedded_svc.settings.disabledMinimizedText = chatDisabledMinimizedText;
  window.embedded_svc.settings.offlineSupportMinimizedText = chatDisabledMinimizedText;
  window.embedded_svc.settings.loadingText = chatLoadingText;
  window.embedded_svc.settings.displayHelpButton = true;
  window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
  window.embedded_svc.settings.entryFeature = 'LiveAgent';

  window.embedded_svc.init(urlSalesForce, urlChatBot, "https://service.force.com", salesForceId, eswLiveAgentDevName, {
    baseLiveAgentContentURL: baseLiveAgentContentURL,
    deploymentId: deploymentId,
    buttonId: buttonId,
    baseLiveAgentURL: baseLiveAgentURL,
    eswLiveAgentDevName: eswLiveAgentDevName,
    isOfflineSupportEnabled: true
  });
}

function addPrivacyNotice()
{
  let contentFormularioOffline = document.getElementsByClassName('offlineSupportUI embeddedServiceLiveAgentStateOfflineSupportDefaultUI');

  if (contentFormularioOffline && contentFormularioOffline[0])
  {
    let formularioOffline = contentFormularioOffline[0].getElementsByClassName('formContent embeddedServiceSidebarForm');
    let buttonEnviar = contentFormularioOffline[0].getElementsByClassName('embeddedServiceSidebarButton');

    if (buttonEnviar && buttonEnviar[0])
    {
      buttonEnviar[0].disabled = true;
    }

    if (formularioOffline && formularioOffline[0])
    {
      let avisoPrivacidad = document.createElement('div');

      avisoPrivacidad.id = 'avisoPrivacidad';
      avisoPrivacidad.classList.add('avisoPrivacidad');
      avisoPrivacidad.style = 'margin-bottom: 15px;';
      avisoPrivacidad.innerHTML = document.getElementById('privacy-notice').innerHTML;

      formularioOffline[0].appendChild(avisoPrivacidad);

      let privacyCheckButton = avisoPrivacidad.getElementsByTagName('input')[0];

      privacyCheckButton.addEventListener('click', function ()
      {
        this.getElementsByClassName('embeddedServiceSidebarButton')[0].disabled = !privacyCheckButton.checked;
      }.bind(contentFormularioOffline[0], this));
    }
  }
}

function afterChatEnded()
{
  setTimeout(function ()
  {
    var endChatDiv = document.getElementsByClassName("endChatContainer");
    if (endChatDiv && endChatDiv[0])
    {
      var saveTranscriptBtn = endChatDiv[0].getElementsByClassName("endChatButton saveTranscriptButton uiButton--inverse uiButton embeddedServiceSidebarButton");
      if (saveTranscriptBtn && saveTranscriptBtn[0] && transferred === 0)
      {
        saveTranscriptBtn[0].setAttribute("class", "endChatButton saveTranscriptButton uiButton--default uiButton embeddedServiceSidebarButton");
        saveTranscriptBtn[0].setAttribute("data-aura-class", "uiButton--default uiButton embeddedServiceSidebarButton");
      }
      var feedbackBtn = endChatDiv[0].getElementsByClassName("endChatButton postChatButton uiButton--default uiButton embeddedServiceSidebarButton");
      if (feedbackBtn && feedbackBtn[0] && transferred === 0)
      {
        feedbackBtn[0].setAttribute("class", "endChatButton postChatButton uiButton--inverse uiButton embeddedServiceSidebarButton");
        feedbackBtn[0].setAttribute("data-aura-class", "uiButton--inverse uiButton embeddedServiceSidebarButton");
        feedbackBtn[0].style.display = "none";
      }
      endChatDiv[0].style.visibility = "visible";
    }

    transferred = 0;
  }, 1000);
}
